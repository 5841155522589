const Meta = {
  home: {
    interviews: [
      {
        title: `Why the U.S. Pension System May Need a Federal Bailout`,
        date: `December 2, 2019`,
        link: `https://www.marketwatch.com/video/marketbrief/why-the-us-pension-system-may-need-a-federal-bailout/E6465767-5D1F-4C0C-AA8B-94A2E7102236.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Is the end of the hedge fund era nigh? Chairman of Ill. State Board of Investment weighs in`,
        date: `Nov 14, 2018`,
        link: `https://www.cnbc.com/video/2018/11/14/is-the-end-of-the-hedge-fund-era-nigh-chairman-of-ill-state-board-of-investment-weighs-in.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Pension Pioneer On The Push Towards Passive`,
        date: `Mar 28, 2018`,
        link: `https://www.youtube.com/watch?v=2jfKtWq5l_o&feature=youtu.be`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Hedge funds ‘destroying enormous value for institutional investors’: Illinois investment board chair`,
        date: `July 18, 2018`,
        link: `https://www.cnbc.com/2018/07/18/hedge-funds-destroying-enormous-value-for-investors-marc-levine.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `CNBC's Alpha Strategy Session on markets, risks and the economy`,
        date: `Aug 2, 2018`,
        link: `https://www.cnbc.com/video/2018/08/02/cnbcs-alpha-strategy-session-on-markets-risks-and-the-economy.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `The Future of Investing: Where Global Growth is Driving Transformation`,
        date: `June 7, 2018`,
        link: `https://vimeo.com/user12308995/review/274782535/99d7834c52`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `The Rise of Passive Strategies in Public Pensions`,
        date: `Apr 30, 2018`,
        link: `https://us.spindices.com/documents/education/institutional-talks-the-rise-of-passive-strategies-in-public-pensions.pdf`,
        isVideo: false,
        isPdf: true
      },
      {
        title: `Why ISBI's Marc Levine Slashed Its Hedge Fund Exposure`,
        date: `Jan 3, 2017`,
        link: `https://www.institutionalinvestor.com/article/b18bk2chvxwkxd/why-isbis-marc-levine-slashed-its-hedge-fund-exposure`,
        isVideo: false,
        isPdf: false
      },
      {
        title: `Three investors who manage more than $900 billion combined discuss the next financial crisis`,
        date: `July 26, 2018`,
        link: `https://www.cnbc.com/2018/07/23/three-experts-predict-next-financial-crisis.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Manager of $23 billion is expecting big returns from an overlooked real estate sector`,
        date: `July31, 2018`,
        link: `https://www.cnbc.com/2018/07/31/money-manger-betting-big-unexpected-investment-non-core-real-estate.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Here's why Illinois is getting out of hedge funds: State Board of Investment Chairman`,
        date: `Feb 7, 2018`,
        link: `https://www.cnbc.com/video/2018/02/07/heres-why-illinois-is-getting-out-of-hedge-funds-state-board-of-investment-chairman.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Mr. Passive on Lowering Costs, Chasing Alpha`,
        date: `Jan 3, 2018`,
        link: `https://www.bloomberg.com/news/videos/2018-01-03/mr-passive-on-lowering-costs-chasing-alpha-video`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Pension Plans, Hedge Funds, and the Way Forward`,
        date: `Jun 7, 2017`,
        link: `https://www.bloomberg.com/news/videos/2017-06-07/pension-plans-hedge-funds-and-the-way-forward-video`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Illinois State Board of Investment details which hedge funds they trust`,
        date: `May 25, 2017`,
        link: `http://video.cnbc.com/gallery/?video=3000621902&play=1`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Hedge fund exodus?`,
        date: `Mar 14, 2017`,
        link: `http://video.cnbc.com/gallery/?video=3000601319&play=1`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `State of Illinois Pulls $2.4 Billion From Active Managers`,
        date: `Nov 18, 2016`,
        link: `http://www.bloomberg.com/news/videos/2016-11-18/state-of-illinois-pulls-2-4-billion-from-active-managers`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Illinois' Levine: We're Removing Honey Pot From Politics`,
        date: `Oct 19, 2016`,
        link: `http://www.bloomberg.com/news/videos/2016-10-19/illinois-levine-we-re-removing-the-honey-pot-from-politics`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Pain across the hedge fund community`,
        date: `Apr 7, 2016`,
        link: `http://video.cnbc.com/gallery/?video=3000507676`,
        isVideo: true,
        isPdf: false
      }
    ],
    opeds: [
      {
        title: `Commentary: A better split for pension funds`,
        date: `Nov 6, 2018`,
        link: `https://www.pionline.com/article/20181106/ONLINE/181109997/commentary-a-better-split-for-pension-funds`,
        isPdf: false
      },
      {
        title: `Why Illinois Got Out of the Hedges`,
        date: `Jan 31, 2018`,
        link: `https://www.wsj.com/articles/why-illinois-got-out-of-the-hedges-1517440169`,
        isPdf: false
      },
      {
        title: `Pension Trustees Must Stop Pretending They Know How To Choose Money Managers`,
        date: `Aug 9, 2017`,
        link: `https://www.forbes.com/sites/investor/2017/08/09/pension-trustees-must-stop-pretending-they-know-how-to-choose-money-managers/#4720268e36c4`,
        isPdf: false
      },
      {
        title: `Watch What Indexing Does for Public Pensions`,
        date: `Feb 10, 2017`,
        link: `https://www.bloomberg.com/opinion/articles/2017-02-10/watch-what-indexing-does-for-public-pensions`,
        isPdf: true
      },
      {
        title: `Fees That Sickly Public-Pension Funds Can’t Afford`,
        date: `May 13, 2016`,
        link: `https://www.wsj.com/articles/fees-that-sickly-public-pension-funds-cant-afford-1463177963`,
        isPdf: false
      }
    ]
  },
  otherMedia: {
    tvInterviews: [
      {
        title: `Hedge funds ‘destroying enormous value for institutional investors’: Illinois investment board chair`,
        date: `July 18, 2018`,
        link: `https://www.cnbc.com/2018/07/18/hedge-funds-destroying-enormous-value-for-investors-marc-levine.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Here's why Illinois is getting out of hedge funds: State Board of Investment Chairman`,
        date: `Feb 7, 2018`,
        link: `https://www.cnbc.com/video/2018/02/07/heres-why-illinois-is-getting-out-of-hedge-funds-state-board-of-investment-chairman.html`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Mr. Passive on Lowering Costs, Chasing Alpha`,
        date: `Jan 3, 2018`,
        link: `https://www.bloomberg.com/news/videos/2018-01-03/mr-passive-on-lowering-costs-chasing-alpha-video`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Pension Plans, Hedge Funds, and the Way Forward`,
        date: `Jun 7, 2017`,
        link: `https://www.bloomberg.com/news/videos/2017-06-07/pension-plans-hedge-funds-and-the-way-forward-video`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Illinois State Board of Investment details which hedge funds they trust`,
        date: `May 25, 2017`,
        link: `http://video.cnbc.com/gallery/?video=3000621902&play=1`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Hedge fund exodus?`,
        date: `Mar 14, 2017`,
        link: `http://video.cnbc.com/gallery/?video=3000601319&play=1`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `State of Illinois Pulls $2.4 Billion From Active Managers`,
        date: `Nov 18, 2016`,
        link: `http://www.bloomberg.com/news/videos/2016-11-18/state-of-illinois-pulls-2-4-billion-from-active-managers`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Illinois' Levine: We're Removing Honey Pot From Politics`,
        date: `Oct 19, 2016`,
        link: `http://www.bloomberg.com/news/videos/2016-10-19/illinois-levine-we-re-removing-the-honey-pot-from-politics`,
        isVideo: true,
        isPdf: false
      },
      {
        title: `Pain across the hedge fund community`,
        date: `Apr 7, 2016`,
        link: `http://video.cnbc.com/gallery/?video=3000507676`,
        isVideo: true,
        isPdf: false
      }
    ],
    nationalArticles: [
      {
        title: `The dying business of picking stocks`,
        date: `Oct 17, 2016`,
        link: `http://www.wsj.com/articles/the-dying-business-of-picking-stocks-1476714749`,
        isVideo: false,
        isPdf: false
      },
      {
        title: `Illinois Reduces Reliance on Active Managers`,
        date: `Nov 15, 2016`,
        link: `http://www.wsj.com/articles/illinois-reduces-reliance-on-active-managers-1479254378`,
        isVideo: false,
        isPdf: false
      },
      {
        title: `Illinois State Pension Board Stops Trying to Beat the Market`,
        date: `Sep 16, 2016`,
        link: `http://www.wsj.com/articles/illinois-state-workers-pension-board-switches-to-funds-that-track-market-1474051849`,
        isVideo: false,
        isPdf: false
      },
      {
        title: `Investors Pull Cash From Hedge Funds as Returns Lag Market`,
        date: `Mar 30, 2016`,
        link: `http://www.wsj.com/articles/investors-message-to-hedge-funds-we-are-replacing-you-with-clones-1459348497`,
        isVideo: false,
        isPdf: false
      },
    ],
    printInterviews: [
      {
        title: `The Rise of Passive Strategies in Public Pensions`,
        date: `Apr 30, 2018`,
        link: `https://us.spindices.com/documents/education/institutional-talks-the-rise-of-passive-strategies-in-public-pensions.pdf`,
        isVideo: false,
        isPdf: true
      },
      {
        title: `Why ISBI's Marc Levine Slashed Its Hedge Fund Exposure`,
        date: `Jan 3, 2017`,
        link: `https://www.institutionalinvestor.com/article/b18bk2chvxwkxd/why-isbis-marc-levine-slashed-its-hedge-fund-exposure`,
        isVideo: false,
        isPdf: false
      }
    ]
  }
}

export default Meta;
