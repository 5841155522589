import React from 'react';
import styled from 'styled-components';

const Row = styled.div`
  width: 1100px;
  margin: 0 auto;
  @media only screen and (max-width: 1260px) {
    width: 950px;
  }
  @media only screen and (max-width: 1090px) {
    width: 768px;
  }
  @media only screen and (max-width: 900px) {
    width: 600px;
  }
  @media only screen and (max-width: 740px) {
    width: 420px;
  }
  @media only screen and (max-width: 480px) {
    width: 80vw;
    margin: 0 10vw;
  }
  @media only screen and (min-width: 1500px) {
    width: 1400px;
  }
`

const Top = styled.div`
  background-image: url(${props => props.bg ? props.bg : ""});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #272e46;
  padding-top: 35px;
  padding-bottom: 5px;
  margin-top: 95px;
  @media screen and (min-width: 1000px) {
    padding-top: 140px;
    padding-bottom: 50px;
    margin-top: 0;
  }
`

const Title = styled.h1`
  text-align: center;
  text-transform: uppercase;
  color: white;
  text-shadow: 1px 0px 1px #272e46, 0px 1px 1px black, 2px 1px 1px #272e46, 1px 2px 1px black, 3px 2px 1px #272e46, 2px 3px 1px black, 4px 3px 1px #272e46, 3px 4px 1px black, 5px 4px 1px #272e46, 4px 5px 1px black, 6px 5px 1px #272e46, 5px 6px 1px black, 7px 6px 1px #272e46;
  margin: 0;
  font-size: 25px;
  line-height: 35px;
  @media only screen and (min-width: 601px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media only screen and (min-width: 769px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media screen and (max-width: 1000px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media screen and (min-width: 1001px) {
    font-size: 35px;
    line-height: 45px;
  }
  @media screen and (min-width: 1300px) {
    font-size: 40px;
    line-height: 55px;
  }
  @media screen and (min-width: 1600px) {
    font-size: 50px;
    line-height: 63px;
  }
`

const Separator = styled.div`
  background-color: white;
  height: 1px;
  width: 73px;
  margin: 10px auto 20px;
`

const TopRow = props => {
  return (
    <Top bg={props.bg}>
      <Title>{props.title}</Title>
      <Separator />
    </Top>
  );
}

const SliderRow = props => {
  return (
    <Row>{props.children}</Row>
  );
}

const Container = styled(Row)`
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`

const ContainerRow = props => {
  return (
    <Container>{props.children}</Container>
  );
}

export { TopRow, SliderRow, ContainerRow }
