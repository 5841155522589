import React from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import MaterialUIForm from 'react-material-ui-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ThumbIcon from '@material-ui/icons/ThumbUp';
import ErrorIcon from '@material-ui/icons/Error';

ReactModal.setAppElement('#root');

const Title = styled.div`
  color: #272e46;
  text-align: center;
  font-size: 26px;
  line-height: 28px;
  text-transform: uppercase;
  text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee,
	2px 1px 1px #ccc, 1px 2px 1px #eee,
	3px 2px 1px #ccc, 2px 3px 1px #eee,
	4px 3px 1px #ccc, 3px 4px 1px #eee,
	5px 4px 1px #ccc, 4px 5px 1px #eee,
	6px 5px 1px #ccc, 5px 6px 1px #eee,
	7px 6px 1px #ccc;
`

const Close = styled.button`
  position: absolute;
  cursor: pointer;
  min-width: 1em;
  z-index: 2;
  height: 24px;
  width: 24px;
  left: auto;
  right: 10px;
  bottom: auto;
  top: 20px;
  padding: 0px;
  color: #272e46;
  font-family: inherit;
  font-size: 32px;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
  background-color: white;
  border: none;
  @media only screen and (max-width: 480px) {
    right: 0px;
  }
`

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccess: false,
      showError: false
    }
  }

  submit = (values, pristineValues) => {
    const url = 'https://script.google.com/macros/s/AKfycbxRqocJxd-zzcfxV7ODApqn7lSchFt2vbHHhfYO123JZnlEdK0/exec';
    const encoded = Object.keys(values).map(k => {
      return encodeURIComponent(k) + "=" + encodeURIComponent(values[k]);
    }).join('&');
    fetch(url, {
      method: 'POST',
      headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
      body: encoded
    }).then(data => {
      console.log(data);
      this.setState({showSuccess: true});
    }).catch(error => {
      this.setState({showError: true});
    })
  }

  render() {
    return (
      <ReactModal
        isOpen={this.props.show}
        onRequestClose={this.props.handleContactClose}
        style={{
          overlay: {
            backgroundColor: 'rgba( 0, 0, 0, 0.90 )'
          },
          content: {
            width: '60%',
            maxWidth: '960px',
            margin: '75px auto',
            borderRadius: '0',
            border: 'none',
            color: '#272e46'
          }
        }}
      >
        <MaterialUIForm onSubmit={this.submit}>
          <Title>Contact Marc Levine</Title>
          <TextField className='field' fullWidth label="Name*" type="text" name="name" value="" data-validators="isRequired" />
          <TextField className='field' fullWidth label="Email*" type="email" name="email" value="" data-validators="isRequired" />
          <TextField className='field' fullWidth label="Company*" type="text" name="company" value="" data-validators="isRequired" />
          <TextField className='field textfield' fullWidth multiline rowsMax="10" label="Message*" type="text" name="message" value="" data-validators="isRequired" />
          <div style={{textAlign: "center", marginTop: "20px"}}>
            <Button variant="contained" color="primary" type="submit">Send</Button>
          </div>
          {this.state.showSuccess && <AppBar position="static" style={{marginTop: "15px"}}>
            <Toolbar>
              <IconButton style={{color: "white"}}>
                <ThumbIcon />
              </IconButton>
                THANK YOU FOR YOUR MESSAGE. IT HAS BEEN SENT.
            </Toolbar>
          </AppBar> }
          {this.state.showError && <AppBar position="static" color="rgb(225, 0, 80)" style={{marginTop: "15px"}}>
            <Toolbar>
              <IconButton style={{color: "white"}}>
                <ErrorIcon />
              </IconButton>
                THERE WAS A PROBLEM SENDING YOUR MESSAGE. PLEASE TRY AGAIN.
            </Toolbar>
          </AppBar> }
          </MaterialUIForm>
        <Close onClick={this.props.handleContactClose}>x</Close>
      </ReactModal>
    );
  }
}

export default ContactForm;
