import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logo from 'assets/logo.png';

library.add(faBars);

const HeaderComponent = styled.header`
  display: flex;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 5px 45px;
  background-color: ${props => props.isTop ? "transparent" : "white"};
  transition: left 1s cubic-bezier(.694,.0482,.335,1);
  height: ${props => props.isTop ? "80px" : "60px"};
  @media screen and (max-width: 1000px) {
    height: auto;
    background-color: white;
    padding: 5px 0;
  }
`

const LogoContainer = styled.div`
  height: ${props => props.isTop ? "70px" : "50px"};
  padding: 0;
  img {
    height: 100%;
  }
  @media screen and (max-width: 1000px) {
    height: 80px;
    padding-top: 5px;
    margin: 0 auto;
    text-align: center;
  }
`

const Nav = styled.nav`
  @media screen and (max-width: 1000px) {
    display: ${props => props.show ? "block" : "none"};
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
  }
`

const Ul = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-items: center;
  height: 100%;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    width: 768px;
    padding-right: calc((100% - 768px) / 2);
    padding-left: calc((100% - 768px) / 2);
  }
  @media only screen and (max-width: 768px) {
    width: 600px;
    padding-right: calc((100% - 600px) / 2);
    padding-left: calc((100% - 600px) / 2);
  }
  @media only screen and (max-width: 600px) {
    width: 420px;
    padding-right: calc((100% - 420px) / 2);
    padding-left: calc((100% - 420px) / 2);
  }
  @media only screen and (max-width: 480px) {
    width: 300px;
    padding-right: calc((100% - 300px) / 2);
    padding-left: calc((100% - 300px) / 2);
  }
`

const Li = styled.li`
  @media screen and (max-width: 1000px) {
    &:not(:last-child) {
      border-bottom: 1px solid #272e46;
    }
    width: 100%;
    a, p {
      color: #272e46;
      font-weight: 400;
      padding: 10px 0;
      display: block;
      text-decoration: none;
      margin: 0;
    }
  }
  @media screen and (min-width: 1001px) {
    border: none;
    line-height: 70px;
    margin: 0 14px;
    color: ${props => props.isTop ? "white" : "black"};
    text-shadow: ${props => props.isTop ? "1px 0px 1px #272e46, 0px 1px 1px black, 2px 1px 1px #272e46, 1px 2px 1px black, 3px 2px 1px #272e46, 2px 3px 1px black, 4px 3px 1px #272e46, 3px 4px 1px black, 5px 4px 1px #272e46, 4px 5px 1px black, 6px 5px 1px #272e46, 5px 6px 1px black, 7px 6px 1px #272e46" : "1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc"};
    &::after {
      position: relative;
      bottom: 0;
      left: 0;
      background-color: ${props => props.isCurrent ? "white" : "transparent"};
      width: 100%;
      height: 5px;
      display: block;
      content: "";
      transition: background-color .15s ease-out;
    }
    &:hover::after {
      background-color: white;
    }
    a,
    p {
      padding: 0 12px;
      margin: 0;
      font-family: 'Raleway', sans-serif;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0px;
      text-transform: uppercase;
      text-decoration: none;
      color: ${props => props.isCurrent && !props.isTop ? "#00aeef" : "inherit"};
      text-shadow: inherit;
    }
  }
`

const BarsContainer = styled.div`
  position: absolute;
  top: 40%;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    padding-left: calc((100% - 768px) / 2);
  }
  @media only screen and (max-width: 768px) {
    padding-left: calc((100% - 600px) / 2);
  }
  @media only screen and (max-width: 600px) {
    padding-left: calc((100% - 420px) / 2);
  }
  @media only screen and (max-width: 480px) {
    padding-left: calc((100% - 300px) / 2);
  }
  @media screen and (min-width: 1001px) {
    display: none;
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      showMenu: false
    }
  }

  handleScroll() {
    if (window.scrollY < 1) {
      this.setState({ isTop: true });
    } else {
      this.setState({ isTop: false });
    }
  }

  handleContactOpen() {
    this.hideMenu()
    this.props.handleContactOpen()
  }

  toggleMenu() {
    this.setState({ showMenu: !this.state.showMenu })
  }

  hideMenu() {
    this.setState({ showMenu: false })
  }

  componentDidMount() {
    window.addEventListener('scroll', () => this.handleScroll())
  }

  render() {
    const path = window.location.pathname.split("/")[1];
    return (
      <HeaderComponent isTop={this.state.isTop}>
        <BarsContainer onClick={() => this.toggleMenu()}>
          <FontAwesomeIcon icon={['fas', 'bars']}/>
        </BarsContainer>
        <LogoContainer onClick={() => this.hideMenu()} isTop={this.state.isTop}>
          <Link to="/">
            <img src={logo} alt="ML"/>
          </Link>
        </LogoContainer>
        <Nav show={this.state.showMenu}>
          <Ul>
            <Li onClick={() => this.toggleMenu()} isCurrent={path === 'other-media'} isTop={this.state.isTop}>
              <Link to="/other-media/">Other Media</Link>
            </Li>
            <Li onClick={() => this.toggleMenu()} isCurrent={path === 'bio'} isTop={this.state.isTop}>
              <Link to="/bio/">Bio</Link>
            </Li>
            <Li isCurrent={false} isTop={this.state.isTop} onClick={() => this.handleContactOpen()}>
              <p>Contact</p>
            </Li>
          </Ul>
        </Nav>
      </HeaderComponent>
    );
  }
}

export default Header;
