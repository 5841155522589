import React from 'react';
import styled from 'styled-components';

const StyledSeparator = styled.div`
  background-color: white;
  height: 1px;
  width: 73px;
  margin: 10px auto 20px;
`

const Separator = () => {
  return (
    <StyledSeparator />
  )
}

export default Separator;
