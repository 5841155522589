import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Separator from 'components/Separator';
import { ContainerRow } from 'components/Row';
import bg from 'assets/bio/bg.jpg';
import portrait from 'assets/bio/portrait.jpg';

const HeadData = () => {
  return (
    <Helmet>
      <title>Marc Levine on Investing | Bio</title>
      <meta name="description" content="Marc Levine on Investing | Investing Excellence Through Simplicity" />
    </Helmet>
  )
}

const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 25px;
  background-image: url(${bg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`

const Title = styled.h2`
  color: #272e46;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc;
`

const OneColumn = styled.div`
  width: 100%;
  margin-top: 3%;
  @media screen and (max-width: 1000px) {
    margin-top: 1.5%;
  }
`

const TwoColumn = styled.div`
  width: 49%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`

const LeftCol = styled(TwoColumn)`
  margin-right: 1%;
  @media screen and (max-width: 1000px) {
    margin: 0;
  }
`

const RightCol = styled(TwoColumn)`
  margin-left: 1%;
  @media screen and (max-width: 1000px) {
    margin: 0;
  }
`

const PortraitCard = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  background-color: #272727;
  border-left-color: #ffffff;
  border-left-style: ridge;
  border-right-color: #ffffff;
  border-right-style: ridge;
  border-top-color: #ffffff;
  border-top-style: ridge;
  border-bottom-color: #ffffff;
  border-bottom-style: ridge;
  @media screen and (max-width: 1000px) {
    margin: 10px 0;
  }
`

const BioCard = styled.div`
  width: calc(100% - 30px);
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  background-color: white;
  border-top-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-left-width: 5px;
  background-color: #ffffff;
  border-left-color: #272e46;
  border-left-style: groove;
  border-right-color: #272e46;
  border-right-style: groove;
  border-top-color: #272e46;
  border-top-style: groove;
  border-bottom-color: #272e46;
  border-bottom-style: groove;
  @media screen and (max-width: 1000px) {
    margin: 10px 0;
  }
`

const BioText = styled.h6`
	margin-top: 3em;
	font-size: 13px;
	line-height: 1.538461538461538em;
	font-weight: 600;
	letter-spacing: 1px;
  text-transform: uppercase;
  @media screen and (max-width: 1499px) and (min-width: 1201px) {
    font-size: 11px;
  	margin-top: 2.75em;
  }
  @media screen and (max-width: 1200px) and (min-width: 1001px) {
    font-size: 11px;
    margin-top: 2.5em;
  }
`

const BioCardTitle = styled.h3`
  color: #272e46;
  font-size: 30px;
  line-height: 35px;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc;
  text-align: center;
  margin-bottom: 15px;
  word-break: break-word;
  @media screen and (max-width: 1000px) {
    font-size: 20px;
    line-height: 28px;
  }
`

const BioCardUl = styled.ul`
  padding-left: 15px;
`

const Bio = () => {
  return (
    <React.Fragment>
      <HeadData />
      <Container>
        <Title>Bio</Title>
        <Separator />
        <ContainerRow>
          <LeftCol>
            <PortraitCard src={portrait} alt=""/>
          </LeftCol>
          <RightCol>
            <BioCard>
              <BioText>Marc Levine is the Chairman of the Illinois State Board of Investment (ISBI), which manages $22 billion on behalf of 140,000 state employees.  Marc was a pioneer in the asset-backed securities industry with transactions including the first-ever securitization of precious metals. Marc was the founding principal of Chicago Asset Funding LLC, a AAA-rated structured-finance investment firm that in 2009 was one of the market’s largest investors in junior collateralized loan obligations.  Prior to that Marc ran similar vehicles for ABN AMRO and Continental Bank in Chicago and began his career in auditing and consulting at KPMG.</BioText>
              <BioText>Marc earned his MBA from Northwestern University’s Kellogg Graduate School of Management, received his B.S. from the University of Florida and is a Certified Public Accountant.  Marc has also served on the S&P Dow Jones Indices US Advisory Panel, the Illinois Teachers Retirement System pension board and the leasing subsidiary of general motors.</BioText>
            </BioCard>
          </RightCol>
        </ContainerRow>
        <ContainerRow>
          <OneColumn>
            <BioCard>
              <BioCardTitle>Illinois State Board of Investments – Accomplishments</BioCardTitle>
              <BioCardUl>
                <li>Defeated entrenched interests to modernize inert pension fund into aspirational national model through complete restructuring:
                  <BioCardUl>
                    <li>Changed culture to investment excellence / beneficiary interests come first</li>
                    <li>Focused on simplicity and alignment of interests</li>
                    <li>Cleaned house – terminated &gt;70% of active managers for extended below benchmark performance</li>
                    <li>Solved government resource constraint – outsourced all active manager selection and reporting to world-class strategic partners</li>
                  </BioCardUl>
                </li>
                <li>Result – a bifurcated $18 billion portfolio:
                  <BioCardUl>
                    <li>$6 billion manageable, focussed alpha-seeking, actively-managed, mostly private, sub-portfolio
                      <BioCardUl>
                        <li>Launched $2.5 billion opportunistic credit and real estate sub-sectors targeting low teens returns with very modest leverage</li>
                        <li>Off-the-run “nooks and crannies” strategy – eg. late stage aircraft, demographic and technology real estate, lot banking, distressed mortgages, BDC IPO premium bumps</li>
                        <li>Consolidated credit-risk sectors, introduced separate accounts</li>
                      </BioCardUl>
                    </li>
                    <li>$12 billion low-cost indexed portfolio
                      <BioCardUl>
                        <li>Launch of multi-factor sleeve in public equity indexed portfolio</li>
                      </BioCardUl>
                    </li>
                  </BioCardUl>
                </li>
                <li>Governance
                  <BioCardUl>
                    <li>Revamped management team – lean and innovative</li>
                    <li>Ended “rubber stamp theater” – removed trustees from individual manager selection</li>
                    <li>Replaced investment policy with coherent four page document</li>
                    <li>Eliminated inherent general consultant conflict with outsource model</li>
                  </BioCardUl>
                </li>
                <li>Simplified DC plan
                  <BioCardUl>
                    <li>Reduced choices from 18 to 7</li>
                    <li>Eliminated all actively-managed options</li>
                    <li>Lowest cost non-federal DC plan in America</li>
                  </BioCardUl>
                </li>
              </BioCardUl>
              <p style={{textAlign: "center"}}>
                <strong>Recognized national leader for utilizing passive investment strategies and outsourced strategic partnerships for sustainable improved performance and targeted alpha opportunities. </strong>
              </p>
            </BioCard>
          </OneColumn>
        </ContainerRow>
      </Container>
    </React.Fragment>
  )
}

export default Bio;
