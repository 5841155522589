import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import interviews1 from 'assets/other-media/tv-interviews/1.jpg';
import interviews2 from 'assets/other-media/tv-interviews/2.jpg';
import interviews3 from 'assets/other-media/tv-interviews/3.jpg';
import interviews4 from 'assets/other-media/tv-interviews/4.jpg';
import interviews5 from 'assets/other-media/tv-interviews/5.jpg';
import interviews6 from 'assets/other-media/tv-interviews/6.jpg';
import interviews7 from 'assets/other-media/tv-interviews/7.jpg';
import interviews8 from 'assets/other-media/tv-interviews/8.jpg';
import interviews9 from 'assets/other-media/tv-interviews/9.jpg';
import articles1 from 'assets/other-media/national-articles/1.jpg';
import articles2 from 'assets/other-media/national-articles/2.jpg';
import articles3 from 'assets/other-media/national-articles/3.jpg';
import articles4 from 'assets/other-media/national-articles/4.jpg';
import print1 from 'assets/other-media/print-interviews/1.jpg';
import print2 from 'assets/other-media/print-interviews/2.jpg';
import institutionalTalksPdf from 'assets/pdf/institutional-talks.pdf';
import Meta from 'pages/Meta';
import { TopRow, SliderRow } from 'components/Row';

const HeadData = () => {
  return (
    <Helmet>
      <title>Marc Levine on Investing | Other Media</title>
      <meta name="description" content="Marc Levine on Investing | Investing Excellence Through Simplicity" />
    </Helmet>
  )
}

const SliderTitle = styled.h3`
  margin: 32px 0;
  color: #272e46;
  font-size: 30px;
  line-height: 35px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc;
`

const Blurb = styled.h6`
  color: #272e46;
  margin-top: 1em;
	margin-bottom: 0;
	font-size: 13px;
	line-height: 1.538461538461538em;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
`

const A = styled.a`
  text-decoration: none;
`

const P = styled.p`
  color: #272e46;
`

const getImageFor = (type, i) => {
  if (type === 'tv') {
    switch (i) {
      case 0:
        return interviews1;
      case 1:
        return interviews2;
      case 2:
        return interviews3;
      case 3:
        return interviews4;
      case 4:
        return interviews5;
      case 5:
        return interviews6;
      case 6:
        return interviews7;
      case 7:
        return interviews8;
      case 8:
        return interviews9;
      default:
        break;
    }
  } else if (type === 'article') {
    switch (i) {
      case 0:
        return articles1;
      case 1:
        return articles2;
      case 2:
        return articles3;
      case 3:
        return articles4;
      default:
        break;
    }
  } else if (type === 'print') {
    switch(i) {
      case 0:
        return print1;
      case 1:
        return print2;
      default:
        break;
    }
  }
}

const interviewSlides = Meta.otherMedia.tvInterviews.map((val, index) => {
  return (
    <A href={val.link} target="_blank" rel="noopener noreferrer" key={index}>
      <span style={{ position: 'relative', width: '100%', height: '100%', display: 'block' }}>
        <svg style={{ position: 'absolute', top: '50%', left: '50%', zIndex: '1' }} version="1.1" xmlns="https://www.w3.org/2000/svg"><g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="btn_play" transform="translate(-30.000000, -24.000000)" fill="#FFFFFF"><g transform="translate(5.000000, 5.000000)" id="Triangle"><polygon points="48.3927837 34.5556641 25.2929688 49.1855469 25.2929688 19.9257812"></polygon></g></g></g></svg>
        <img src={getImageFor('tv', index)} alt="" />
      </span>
      <Blurb>{val.title}</Blurb>
      <P>{val.date}</P>
    </A>
  )
})

const articleSlides = Meta.otherMedia.nationalArticles.map((val, index) => {
  return (
    <A href={val.link} target="_blank" rel="noopener noreferrer" key={index}>
      <img src={getImageFor('article', index)} alt="" />
      <Blurb>{val.title}</Blurb>
      <P>{val.date}</P>
    </A>
  )
})

const printSlides = Meta.otherMedia.printInterviews.map((val, index) => {
  return (
    <A href={val.isPdf ? institutionalTalksPdf : val.link} target="_blank" rel="noopener noreferrer" key={index}>
      <img src={getImageFor('print', index)} alt="" />
      <Blurb>{val.title}</Blurb>
      <P>{val.date}</P>
    </A>
  )
})

const settings = {
  infinite: false,
  slidesToShow: 4,
  responsive: [{
    breakpoint: 900,
    settings: {
      slidesToShow: 3
    }
  }, {
    breakpoint: 768,
    settings: {
      slidesToShow: 2
    }
  }, {
    breakpoint: 500,
    settings: {
      slidesToShow: 1
    }
  }]
};

const Media = () => {
  return (
    <React.Fragment>
      <HeadData />
      <TopRow title="Other Media" />
      <SliderRow>
        <SliderTitle>Television Interviews</SliderTitle>
        <Slider {...settings}>
          {interviewSlides}
        </Slider>
      </SliderRow>
      <SliderRow>
        <SliderTitle>National Articles</SliderTitle>
        <Slider {...settings}>
          {articleSlides}
        </Slider>
      </SliderRow>
      <SliderRow>
        <SliderTitle>Print Interviews</SliderTitle>
        <Slider {...settings}>
          {printSlides}
        </Slider>
      </SliderRow>
      <div style={{ marginBottom: "128px" }} />
    </React.Fragment>
  )
}

export default Media;
