import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ContactForm from 'components/ContactForm';
import Home from 'pages/Home';
import Media from 'pages/Media';
import Bio from 'pages/Bio';
import 'styles/fonts.css';
import 'styles/carousel.css';
import 'styles/form.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#272e46'
    }
  },
});

class App extends React.Component {
  state = {
    showContactForm: false
  }

  handleContactOpen() {
    this.setState({showContactForm: true});
  }

  handleContactClose() {
    this.setState({showContactForm: false})
  }

  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <Header handleContactOpen={() => this.handleContactOpen()} />
          <Route path="/" exact component={Home} />
          <Route path="/other-media" component={Media} />
          <Route path="/bio" component={Bio} />
          <Footer />
          <ContactForm show={this.state.showContactForm} handleContactClose={() => this.handleContactClose()}/>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
