import React from 'react';
import styled from 'styled-components';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

library.add(faTwitter)

const FooterComponent = styled.footer`
  background-color: #292f33;
  text-align: center;
  padding: 5px 0;
  width: 100%;
`

const SocialIconContainer = styled.span`
  background-color: white;
  border-radius: 50%;
  margin: .2307692307692308em;
  transition: all .3s ease-out;
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
  font-size: 3em;
`

const Footer = () => {
  return (
    <FooterComponent>
      <a itemProp="url" href="https://twitter.com/marclevine63" target="_blank" rel="noopener noreferrer">
        <SocialIconContainer>
          <FontAwesomeIcon icon={['fab', 'twitter']} size="xs" color="#272e46"/>
        </SocialIconContainer>
      </a>
    </FooterComponent>
  )
}

export default Footer;
