import React from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bg from 'assets/home/bg.jpg';
import interviews1 from 'assets/home/interviews/1.jpg';
import interviews2 from 'assets/home/interviews/2.jpg';
import interviews3 from 'assets/home/interviews/3.jpg';
import interviews4 from 'assets/home/interviews/4.jpg';
import interviews5 from 'assets/home/interviews/5.jpg';
import interviews6 from 'assets/home/interviews/6.jpg';
import interviews7 from 'assets/home/interviews/7.jpg';
import interviews8 from 'assets/home/interviews/8.jpg';
import interviews9 from 'assets/home/interviews/9.jpg';
import interviews10 from 'assets/home/interviews/10.jpg';
import interviews11 from 'assets/home/interviews/11.jpg';
import interviews12 from 'assets/home/interviews/12.jpg';
import interviews13 from 'assets/home/interviews/13.jpg';
import interviews14 from 'assets/home/interviews/14.jpg';
import interviews15 from 'assets/home/interviews/15.jpg';
import interviews16 from 'assets/home/interviews/16.jpg';
import interviews17 from 'assets/home/interviews/17.jpg';
import opeds1 from 'assets/home/opeds/1.jpg';
import opeds2 from 'assets/home/opeds/2.jpg';
import opeds3 from 'assets/home/opeds/3.jpg';
import opeds4 from 'assets/home/opeds/4.jpg';
import opeds5 from 'assets/home/opeds/5.jpg';
import bloombergPdf from 'assets/pdf/bloomberg-feb-2017.pdf';
import institutionalTalksPdf from 'assets/pdf/institutional-talks.pdf';
import Meta from 'pages/Meta';
import { TopRow, SliderRow } from 'components/Row';

const HeadData = () => {
  return (
    <Helmet>
      <title>Marc Levine on Investing | Home</title>
      <meta name="description" content="Marc Levine on Investing | Investing Excellence Through Simplicity" />
    </Helmet>
  )
}

const SliderTitle = styled.h3`
  margin: 32px 0;
  color: #272e46;
  font-size: 30px;
  line-height: 35px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc, 1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc, 3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc, 5px 6px 1px #eee, 7px 6px 1px #ccc;
`

const A = styled.a`
  text-decoration: none;
`

const P = styled.p`
  color: #272e46;
`

const Blurb = styled.h6`
  color: #272e46;
  margin-top: 1em;
	margin-bottom: 0;
	font-size: 13px;
	line-height: 1.538461538461538em;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: uppercase;
`

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meta: Meta
    }
  }

  getImageFor(type, i) {
    if (type === 'interviews') {
      switch (i) {
        case 0:
          return interviews1;
        case 1:
          return interviews2;
        case 2:
          return interviews3;
        case 3:
          return interviews4;
        case 4:
          return interviews5;
        case 5:
          return interviews6;
        case 6:
          return interviews7;
        case 7:
          return interviews8;
        case 8:
          return interviews9;
        case 9:
          return interviews10;
        case 10:
          return interviews11;
        case 11:
          return interviews12;
        case 12:
          return interviews13;
        case 13:
          return interviews14;
        case 14:
          return interviews15;
        case 15:
          return interviews16;
        case 16:
          return interviews17;
        default:
          break;
      }
    } else if (type === 'opeds') {
      switch (i) {
        case 0:
          return opeds1;
        case 1:
          return opeds2;
        case 2:
          return opeds3;
        case 3:
          return opeds4;
        case 4:
          return opeds5;
        default:
          break;
      }
    }
  }

  render() {
    const settings = {
      infinite: false,
      slidesToShow: 4,
      responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }, {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      }]
    };
    const interviewSlides = this.state.meta.home.interviews.map((val, index) => {
      return (
        <A href={val.isPdf ? institutionalTalksPdf : val.link} target="_blank" rel="noopener noreferrer" key={index}>
          {val.isVideo ? <span style={{ position: 'relative', width: '100%', height: '100%', display: 'block' }}>
            <svg style={{ position: 'absolute', top: '50%', left: '50%', zIndex: '1' }} version="1.1" xmlns="https://www.w3.org/2000/svg"><g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="btn_play" transform="translate(-30.000000, -24.000000)" fill="#FFFFFF"><g transform="translate(5.000000, 5.000000)" id="Triangle"><polygon points="48.3927837 34.5556641 25.2929688 49.1855469 25.2929688 19.9257812"></polygon></g></g></g></svg>
            <img src={this.getImageFor('interviews', index)}  alt="" />
          </span> : <img src={this.getImageFor('interviews', index)} alt="" />}
          <Blurb>{val.title}</Blurb>
          <P>{val.date}</P>
        </A>
      )
    })

    const opedSlides = this.state.meta.home.opeds.map((val, index) => {
      return (
        <A href={val.isPdf ? bloombergPdf : val.link} target="_blank" rel="noopener noreferrer" key={index}>
          <img src={this.getImageFor('opeds', index)} alt="" />
          <Blurb>{val.title}</Blurb>
          <P>{val.date}</P>
        </A>
      )
    })

    return (<React.Fragment>
      <HeadData />
      <TopRow title="Marc Levine on Investing" bg={bg} />
      <SliderRow>
        <SliderTitle>Interviews</SliderTitle>
        <Slider {...settings}>
          {interviewSlides}
        </Slider>
      </SliderRow>
      <SliderRow>
        <SliderTitle>Op-Eds</SliderTitle>
        <Slider {...settings}>
          {opedSlides}
        </Slider>
      </SliderRow>
      <div style={{marginBottom: "128px"}} />
    </React.Fragment> );
  }
}

export default Home;
